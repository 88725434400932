.sustainability-top-heading {
  border-bottom: 1px solid #eeeeee;
  padding: 20px 100px;
  margin-bottom: 20px;
  background-color: #fff;
}

.sustainability-top-heading h1 {
  padding: 0 10px;
  font-weight: 400;
  font-size: 1.4rem;
}

.charts-container {
  padding: 30px 90px;
  background-color: #f9f9f9;
}

.charts-container h2 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 14px;
  /* white-space: nowrap; */
}

.sustainability-section_heading {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 22px;
  margin-bottom: 20px;
}

.sustainability-heading {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.sustainability-heading_container {
  padding: 40px 10px 60px 10px;
  overflow: hidden; /* To ensure the pseudo-elements are contained */
  margin-bottom: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/sustainabilityReport/sustainabilityHeading.png");
  background-color: white;
  border-radius: 12px;
}

.sustainability-heading_title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sustainability-heading_title h1 {
  font-size: 30px;
  font-weight: 600;
}

.sustainability-heading_title h2 {
  font-size: 30px;
  font-weight: 400;
  margin: 0;
}

.sustain-subheading-h2 {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}

.chart-left-label {
  font-weight: 600;
  font-size: 12px;
}

.sustain-base-grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr; /* 1st column is small, next 2 columns are equal width */
  gap: 16px; /* Optional: Add some spacing between columns */
  width: 100%; /* Ensures the grid takes up the full width of its container */
}

.sustain-base-grid-item {
  padding: 16px;
  border: 1px solid #ddd; /* Optional: Border for grid items */
}

.sustain-base-small-column {
  grid-column: 1 / 2; /* Specifies the 1st column */
}

.sustain-base-equal-column {
  grid-column: span 1; /* Specifies the 2nd and 3rd columns */
}

.sustain-environmental-grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr; /* 1st column is small, next 2 columns are equal width */
  gap: 16px; /* Optional: Add some spacing between columns */
  width: 100%; /* Ensures the grid takes up the full width of its container */
}

.sustain-environmental-grid-item {
  padding: 16px;
  border: 1px solid #ddd; /* Optional: Border for grid items */
}

.sustain-environmental-small-column {
  grid-column: 1 / 2;
  padding: 16px;
}

.sustain-environmental-equal-column {
  grid-column: span 1; /* Specifies the 2nd and 3rd columns */
}

.half-doughnut-legend {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
}

.base-sector-card_title {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 6px;
}

.base-sector-card_grade {
  font-weight: 500;
  font-size: 14px;
}

.grading-section {
  margin-bottom: 60px;
}

.disclaimer-div {
  margin-top: 60px;
  margin-bottom: 15%;
}

.div-margin {
  margin-bottom: 0;
}

.disclaimer-div h3 {
  margin: 0 0 10px 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.94px;
}

.disclaimer-div ul {
  margin-left: 15px;
  list-style: decimal;
}

.disclaimer-div ul li {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 5px;
}

.sustainability-bottom_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  padding: 10px 90px;
  border-top: 1px solid #dbdbdb;
  position: fixed;
  bottom: 0;
  background-color: #fff;
}

.sustainability-bottom_div-container {
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dbdbdb;
  background-color: #fff;
}

.sustainability-bottom_div1 {
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.verification-btn {
  padding: 12px 18px;
  border-radius: 6px;
  font-size: 1rem;
  color: #fff;
  border: none;
  cursor: pointer;
  background: linear-gradient(102deg, #369d9c 0%, #28814d 100%);
}

/* .btn-no_borders {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
} */

.btn-no_borders {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  display: inline-block;
  text-decoration: none;
  background-clip: text;
}

.btn-no_borders::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 0;
  height: 1.5px;
  width: 100%;
  background: transparent;
  transition: background 0.3s ease;
}

.btn-no_borders:hover {
  background: linear-gradient(101.74deg, #369d9c 0%, #28814d 100%);
  -webkit-background-clip: text;
  color: transparent; /* This makes sure the gradient is visible */
}

.btn-no_borders:hover::after {
  background: linear-gradient(101.74deg, #369d9c 0%, #28814d 100%);
}

.expert-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dbdbdb;
  padding: 10px 15px;
  position: fixed;
  bottom: 10%;
  left: 50%; /* Center horizontally */
  transform: translateX(
    -50%
  ); /* Center by shifting left by 50% of the element's width */
  width: 96%;
  background-color: #fff;
  border-radius: 12px;
}

.expert-left_div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.expert-left_div h2 {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 24px;
}

.expert-left_div h3 {
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 24px;
  color: #699191;
}

.expert-right_div {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 10px 0 0;
}

.expert-right_div .book-btn {
  cursor: pointer;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #699191;
  font-size: 0.8rem;
  padding: 12px;
  color: #699191;
  width: 100px;
}

.expert-right_div .close-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.score-breakdown_heading {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
  margin-bottom: 0.8rem !important;
}

.score-breakdown_total_kpis {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
}

.score-breakdown_score {
  font-weight: 600 !important;
  font-size: 3rem !important;
}


/* Modal CSS */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 24px;
  max-width: 700px;
  width: 100%;
  
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: right;
  gap: 16px;
  margin-top: 24px;
}
.file-upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
  transition: all 0.3s ease;
  cursor: pointer;
}

.file-upload-area.dragging {
  border-color: #2196f3;
  background-color: rgba(33, 150, 243, 0.1);
}

.file-upload-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.upload-icon {
  font-size: 24px;
}

.selected-files {
  margin: 15px 0;
  max-height: 150px;
  overflow-y: auto;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 5px;
}

.remove-file {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  font-size: 18px;
  padding: 0 5px;
}

/* .modal-content {
  max-width: 500px;
  width: 100%;
} */

.success-modal {
  text-align: center;
  padding: 32px;
  max-width: 400px;
}

.success-modal img {
  width: 64px;
  height: 64px;
}
