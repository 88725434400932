.upload_docs-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.upload_docs-modal h2 {
  font-weight: 500;
  font-size: 1.5rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
}

.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  width: 100%;
  padding: 1.5rem;
  text-align: center;
  background-color: #f9f9f9;
}

.drop-zone-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* max-width: 18.875rem; */
  gap: 1rem;
  /* margin: 0 auto; */
  color: #8f8f97;
}

.drop-zone-label {
  color: #28814d;
  font-size: 1rem;
}

.file-preview-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal-width columns */
  gap: 1rem; /* Adds space between the grid items */
}

.file-preview {
  display: flex;
  align-items: stretch;
  position: relative;
}

.file-preview-img {
  padding: 0.75rem;
  background-color: #e5fff0;
}

.file-preview .file-preview_name {
  font-size: 11px;
  color: #a0a0a0;
  font-weight: 400;
  padding: 1rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-file {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.outside-upload-actions {
  width: 100%;
  text-align: center;
}

/* .outside-upload-actions button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  background-color: #28814d;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.outside-upload-actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} */

.upload-and-send {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
