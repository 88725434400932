:root {
  --greenGradient: linear-gradient(101.74deg, #369d9c 0%, #28814d 100%);
}

.dashboard-report_heading {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 16px;
  padding: 0;
  color: #1c1c1c;
}

.drafts-container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  flex-grow: 1;
  overflow-x: auto;
}

.report_card {
  min-width: 17rem;
  /* width: 100%; */
  padding: 1rem;
  max-height: 9.3rem;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #fff;
  scrollbar-width: thin !important;
}

.report_card-heading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report_card-heading-notifications {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.report_card-heading {
  font-size: 1rem;
  font-weight: 500;
}

.report_card-notification {
  background-color: #ff3a3a;
  padding: 0.2rem 0.5rem;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: white;
}

.report_card-progress-container {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.progress-bar {
  width: 45px; /* Adjust width */
  height: 45px; /* Adjust height */
  border-radius: 100%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#369d9c 0%, #28814d 75%, #c8ecfb 75%);
  position: relative; /* Position relative to position the text absolutely */
  display: flex; /* Flexbox to center the text */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  box-shadow: 0px 3.94px 3.94px 0px #228d8c54;
}

.progress-text {
  position: absolute; /* Absolutely positioned relative to .progress-bar */
  font-size: 10px; /* Adjust text size */
  font-weight: 400; /* Adjust font weight */
  color: #000; /* Adjust text color */
}

.report_card-status {
  color: #464646;
  font-size: 0.8rem;
  font-weight: 500;
}

.report_card-edit_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.edited-status {
  font-size: 11px;
  font-weight: 400;
  color: #699191;
}

.extra-count {
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
  border-radius: 50%;
  background-color: #ff3a3a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  margin-left: -8px; /* Align with overlapping images */
  z-index: 1;
  border: 2px solid #fff;
}

.dashboard-verification_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.verification_card {
  max-width: 22rem;
  width: 100%;
  /* max-height: 10rem;
  height: 100%; */
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  background-color: #fff;
  cursor: pointer;
}

.verification_card-heading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
}

.verification_card-heading {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis ("...") */
  width: 180px; /* Adjust the width as necessary */
  display: block;
}

.verification_card-status_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-shrink: 0;
}

.verification_card-status_div {
  max-width: 5.3rem;
  width: 100%;
  max-height: 1.5rem;
  height: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 11px;
  border-radius: 6px;
}

.verification_card-progress_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.verification_card-percent {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 29.05px;
}

.verification_card-percent-name {
  font-weight: 400;
  font-size: 0.8rem;
}

.verification_card-grading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verification_card-grade {
  font-weight: 500;
  font-size: 0.9rem;
  color: #4b4646;
}

.verification_card-grade_date {
  font-size: 0.75rem;
  color: #9b9b9b;
  font-weight: 400;
}

.vetting_table-container {
  /* max-width: 100%; */
  /* min-width: 520px; */
  overflow-x: auto;
}

.vetting_table-heading {
  color: #727272;
  font-size: 0.8rem;
  padding: 0.6rem;
  min-width: 120px;
}

.vetting_table-cell {
  font-size: 0.8rem;
  padding: 0.9rem 0.6rem;
  font-weight: 400;
  min-width: 120px;
}
