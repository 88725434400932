.message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.message-bubble {
  padding: 10px 0;
  border-radius: 10px;
  max-width: 80%; /* Limit width of messages */
  word-wrap: break-word;
  font-size: 0.9rem;
}

.message-time {
  font-size: 0.75rem;
  color: #999;
  margin-top: 5px;
}

/* Align timestamp to the right for 'me' */
.me .message-time {
  align-self: flex-end; /* Timestamp aligned to the right */
}

/* Align timestamp to the left for 'other' */
.other .message-time {
  align-self: flex-start; /* Timestamp aligned to the left */
}

.me .message-bubble {
  align-self: flex-end; /* Right align for messages sent by me */
}

.other .message-bubble {
  align-self: flex-start; /* Left align for messages from others */
  color: black;
  margin-left: 2.375rem;
}

.message-sender {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.message-sender_details {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.comment-input {
  width: 100%;
  border: none;
  outline: none;
}

.comment-input_container {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid #cecece;
  border-radius: 5px;
}

.comment-actions {
  display: flex;
  gap: 1rem;
}

.comment-actions button {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.attached-file {
  margin-top: 10px;
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  word-wrap: break-word;
  max-width: 70%;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
}

/* Align files sent by me to the right */
.me-file {
  background-color: #e6f5ee; /* Light green color for sent files */
  /* text-align: right; */
}

/* Align files from others to the left */
.other-file {
  background-color: #f0f0f0; /* Light gray for received files */
  text-align: left;
}
