:root {
  --booking-gradient: linear-gradient(101.74deg, #369d9c 0%, #28814d 100%);
}

.booking-heading {
  max-width: 220px;
}

.booking-heading h2 {
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 24px;
}

.booking-heading p {
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 16px;
  color: #9a9a9a;
}

.booking-btn {
  cursor: pointer;
  border: none;
  padding: 0.6rem;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  background: var(--booking-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-image-slice: 1;
  border-image-source: var(--booking-gradient);
  position: relative;
  z-index: 1;
}

.booking-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: -1;
  background: var(--booking-gradient);
  padding: 1.5px; /* Adjusts the thickness of the border */
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.tab-panel_child.reports-tab {
  padding-inline: 0;
}

.reports-table_container {
  padding: 20px 32px 10px 32px;
  height: 100%;
  max-width: 100%;
  height: 100%;
  /* overflow: auto; */
}

.reports-table_heading-parent {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}

.reports-table_heading_container {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
}

.reports-table_heading_container h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.reports-table-input_container {
  /* margin-bottom: 32px; */
  display: flex;
  align-items: center;
  gap: 16px;
}

.reports-table-input.MuiOutlinedInput-input {
  padding: 10px;
  border-radius: 8px;
  max-width: 320px;
}

.reports-table-container {
  /* max-width: 100%; */
  max-width: 100%;
  /* overflow: auto; */
}

.reports-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* min-width: 600px; */
  max-width: 100%;
  /* overflow: auto; */
}

.reports-table-row {
  display: flex;
  align-items: center;
  border: 1px solid #ede5e5;
  border-radius: 8px; /* Rounded corners for each row */
  height: 54px;
}

.reports-table-header {
  font-weight: 500;
  color: #8a8686cc;
  background-color: transparent;
  border: none;
  padding: 0;
}

.reports-table-cell {
  flex: 1;
  padding: 0.8rem 2rem;
  text-align: left;
  vertical-align: middle;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 22.5px;
  white-space: nowrap;
  min-width: 150px;
}

.reports-table-cell:last-child {
  text-align: center;
}

/* .reports-table-cell:nth-last-child(2) {
  text-align: center;
} */

.report-verified {
  color: #009952;
}
.report-unverified {
  color: #ed4a3c;
}

.report-actions-btn {
  cursor: pointer;
  border: 1px solid #3cb477;
  border-radius: 100px;
  font-weight: 500;
  line-height: 24px;
  font-size: 0.8rem;
  padding: 0.27rem 1.1rem;
  color: #3cb477;
  background-color: transparent;
}

.report-inProgress-btn {
  cursor: pointer;
  border: 1px solid #438fba;
  border-radius: 100px;
  font-weight: 500;
  line-height: 24px;
  font-size: 0.8rem;
  padding: 0.27rem 1.1rem;
  color: #438fba;
  background-color: transparent;
}
