:root {
  --tab-gradient: linear-gradient(101.74deg, #369d9c 0%, #28814d 100%);
}

.add-data-points_container {
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-data-points_heading {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 24px 0 24px;
}

.add-data-points_heading_container {
  padding: 0 32px 0 38px;
}

.add-data-points_heading button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.add-data-points_heading h2 {
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}

.add-data-points_tabs_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 16px; */
  padding: 0 32px 0 32px;
}

.add-data-points_tabs_container button {
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 10px 15px;
  border: none;
  background: radial-gradient(
    50% 132.61% at 50% 50.13%,
    #2e7b54 0%,
    #00191d 100%
  );
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  box-shadow: 0px 4.43px 13.29px -8.86px #ffffff3d inset,
    0px 19.93px 17.71px -8.86px #005c3136,
    0px -13.29px 17.71px 0px #ffffff38 inset, 0px 4.43px 8.86px -4.43px #005c31,
    0px 2.21px 6.64px 0px #ffffff40 inset, 0px 1.11px 0px -4.43px #005c31;
}

.add-data-points_tab {
  padding: 11px;
  border-radius: 8px;
  cursor: pointer;
  /* Set a transparent border to reserve space for the active tab */
  border: 1px solid transparent;
  /* transition: all 0.3s ease; */
}

.add-data-points_tab.activeTab {
  padding: 6px 11px;
  /* Use an inset box-shadow instead of a border to avoid changing dimensions */
  box-shadow: inset 0 0 0 1px #02a673;
  border-radius: 8px;
  cursor: pointer;
}

.add-data-points_tabName {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #9d9d9d;
  background: transparent;
  /* Keep text consistent when not active */
}

.add-data-points_tabName:hover {
  color: #00191d;
}

.add-data-points_tabName.active {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  background: var(--tab-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  /* Ensure that font size and padding do not change between states */
}

.add-data-points_divider {
  border-bottom: 1px solid #efefef;
}

.add-data-points_content {
  padding: 0 32px 0 32px;
  /* flex-grow: 1; */
  /* overflow: auto; */
}

.add-data-points_table_container {
  width: 100%;
  overflow-x: auto;
}

.kpi-table {
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 2fr 1fr; /* IMP --  comments column is removed for now check later */
  row-gap: 24px; /* Optional: adds space between cells */
}

.no-gap {
  margin-top: -40px; /* Offset the row gap for this specific row */
}

.kpi-table-row {
  display: contents; /* Allows the rows to be in the grid */
}

.kpi-table-cell {
  /* padding: 10px; */
  background-color: #f9f9f9;
  /* border: 1px solid #f2f2f2; */
  font-size: 12px;
}

.kpi-table-heading {
  border-bottom: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 14.42px;
  background-color: #fcfcfc;
}
.kpi-table-heading-cell {
  padding: 5px 0;
  color: #5b5b5b;
  padding: 19px 40px 0 40px;
  margin-bottom: -20px;
  border: none;
}

.center-content {
  display: flex;
  justify-content: center;
}

.kpi-table-symbol_div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16.9px;
  white-space: nowrap;
  border: 1px solid #f2f2f2;
}

.kpi-table-data_point_div {
  padding: 19px 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16.9px;
  white-space: nowrap;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-left: none;
}

.kpi-table-data_unit_div {
  padding: 19px 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16.9px;
  white-space: nowrap;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-left: none;
}

.kpi-table-data_value_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.kpi-table-data_value_div input {
  max-width: 100px;
  height: 36px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  outline: none;
  font-size: 12px;
}

.kpi-table-data_value_div input::placeholder {
  color: #787878;
}

.assignee-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 35px;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
  border-left: 1px solid #f2f2f2;
}

.assignments-div {
  width: 100%;
}

.kpi-table-cell .assignee-cell {
  background-color: #fff;
}

.messages-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 10px;
}

.notifications-div {
  display: flex;
  align-items: center;
  gap: 4.5px;
  padding: 4px 8px;
  border: 1px solid #77bcbb;
  border-radius: 24px;
}

/* .notifications-div span {
  font-size: 12px;
  font-weight: 600;
  color: #fb8183;
} */

.notifications-div .unread {
  font-size: 12px;
  font-weight: 600;
  color: #fb8183;
  white-space: nowrap;
}

.notifications-div .read {
  font-size: 12px;
  font-weight: 600;
  color: #77bcbb;
  white-space: nowrap;
}

.profile-images-container {
  display: flex;
  align-items: center;
}

.profile-images-container img {
  border: 2px solid white;
  border-radius: 20px;
}
.profile-images-container img:not(:first-child) {
  margin-left: -10px;
}

.remaining-count {
  background-color: red;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: -10px;
  border: 2px solid white;
  font-size: 10px;
}


.extra-images {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ff3a3a;
  color: white;
  font-size: 10px;
  font-weight: bold;
  margin-left: -10px; /* Overlapping effect */
  border: 2px solid white;
}

.assign-btn {
  max-width: 96px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 8.5px 28px;
  border: 1px solid #77bcbb;
  color: #77bcbb;
  border-radius: 24px;
  cursor: pointer;
}

.kpi-table-btns_div {
  padding: 10px 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 2rem;
}

.kpi-table-prev-btn {
  cursor: pointer;
  padding: 6px 28px;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid #02a673;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: #02a673;
}

.kpi-table-next-btn {
  cursor: pointer;
  padding: 6px 28px;
  background: var(--tab-gradient);
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: white;
  border: none;
  box-sizing: border-box;
}

/* AI STYLES */
.ai-generated {
  background-color: #f6fffa;
}

/* NEW TABLE CODE */

.new-kpi-table-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.new-kpi-table {
  max-width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.new-kpi-table-header {
  margin-bottom: 10px;
  border: none !important;
}

.new-kpi-table-head-row,
.new-kpi-table-row {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1.3fr 1fr; /* IMP --  comments column is removed for now check later */
  background-color: white;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  border-bottom: none;
}

.new-kpi-table-head-row {
  background-color: #fcfcfc;
  border: none !important;
}

.new-kpi-table-cell {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  position: relative;
  min-width: 0; /* Ensure cells don't overflow */
  min-width: 150px;
}

.header-cell {
  border: none !important;
  background-color: #fcfcfc !important;
}

.new-kpi-table-cell:last-child {
  border-right: none;
}

.new-kpi-table-cell:first-child {
  border-left: none;
}

/* Header cell styles */
.new-kpi-table-header-content {
  padding: 8px 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  border: none !important;
  white-space: nowrap;
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 600;
}

/* Center only specific header cells */
.new-kpi-table-head-row
  .new-kpi-table-cell:nth-child(1)
  .new-kpi-table-header-content,
.new-kpi-table-head-row
  .new-kpi-table-cell:nth-child(4)
  .new-kpi-table-header-content,
.new-kpi-table-head-row
  .new-kpi-table-cell:nth-child(5)
  .new-kpi-table-header-content {
  justify-content: center;
}

/* Content cell styles */
.new-kpi-table-cell-content {
  padding: 10px 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 500;
}

/* Center specific columns in content */
.new-kpi-table-cell:nth-child(1) .new-kpi-table-cell-content,
.new-kpi-table-cell:nth-child(4) .new-kpi-table-cell-content,
.new-kpi-table-cell:nth-child(5) .new-kpi-table-cell-content {
  justify-content: center;
}

/* Data and input wrapper styles */
.data,
.input-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 3px;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
}

.data:last-child,
.input-wrapper:last-child {
  border-bottom: none;
}

.input-field {
  width: 75%;
  padding: 8px 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  border: 0.5px solid #dbdbdb;
}

.input-field::placeholder {
  color: #dbdbdb;
  font-size: 10px;
}

.new-kpi-table-row:first-child .new-kpi-table-cell {
  border-top: none;
}

/* .new-kpi-table-row:last-child .new-kpi-table-cell {
  border-bottom: none;
} */

.new-kpi-table-body-row {
  margin-bottom: 30px;
}

.new-kpi-table-cell > .new-kpi-table-cell-content:last-child {
  border-bottom: none;
}

.yes-no-btn {
  padding: 9px 13px;
  width: 36%;
  border: none;
  font-weight: 600;
  font-size: 11px;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid #d3d3d3;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yes-no-btn.yes-btn {
  color: #329687;
  border: 2px solid #d3d3d3; /* Light grey border */
  text-align: center;
  cursor: pointer;
  background: none;
}

.yes-no-btn.yes-btn.yes-btn-selected {
  background: #e7ffe7;
  color: "#329686";
  border: none;
}

.yes-no-btn.no-btn {
  color: #ff9494;
  border: 2px solid #d3d3d3; /* Light grey border */
  text-align: center;
  cursor: pointer;
  background: none;
}

.yes-no-btn.no-btn.no-btn-selected {
  background: #ffdbdb;
  color: "#FF9494";
  border: none;
}
