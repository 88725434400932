.terms-color {
  color: #000;
}

.terms-heading {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1.6rem;
}

.terms-para {
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  line-height: 1.4rem;
}

.terms-list {
  margin-left: 1rem;
}

.terms-list li {
  font-weight: 400;
  font-size: 0.9rem;
  /* margin-bottom: 0.5rem; */
  line-height: 1.5rem;
}

.terms-btn_div {
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  align-items: center;
  width: 100%;
}

.terms-btn_div button {
  width: 45%;
}

.terms-cancel_btn {
  background: none; /* No background color */
  border: 1px solid transparent; /* Transparent border to show gradient */
  border-radius: 6px; /* Rounded corners */
  padding: 0.8rem; /* Adjust padding for size */
  font-size: 0.875rem; /* Adjust font size */
  font-weight: 500; /* Slightly bolder font */
  cursor: pointer;
  position: relative;
  color: #369d9c; /* Color matching the gradient */
  text-transform: capitalize;
  transition: all 0.3s ease;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, #369d9c, #28814d);
  background-origin: border-box;
  background-clip: padding-box, border-box; /* Ensures gradient border */
}

.terms-accept_btn {
  background: linear-gradient(
    90deg,
    #369d9c,
    #28814d
  ); /* Gradient background */
  border: none;
  border-radius: 6px;
  padding: 0.8rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white; /* White text */
  text-align: center; /* Center the text */
  cursor: pointer; /* Pointer cursor on hover */
  text-transform: none; /* Prevent text transformation */
  display: inline-block; /* Make it inline-block */
  width: 100%; /* Full width */
  transition: all 0.3s ease; /* Smooth transition */
}
