.page-heading {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 14px 12px 14px 0;
}

.page-heading h1 {
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}
