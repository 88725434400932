.assignments-container {
  padding: 0.75rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-height: 80vh; /* Control maximum height for modal */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.assignments-title {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 21px;
}

.input-capsule_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 5px;
  gap: 0.5rem;
  background-color: #fff;
  border-bottom: 2px solid #28814d;
}

.user_capsule {
  background: linear-gradient(
    101.74deg,
    rgba(54, 157, 156, 0.1) 0%,
    rgba(40, 129, 77, 0.1) 100%
  );
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.3rem;
  border-radius: 2rem;
  font-size: 0.875rem;
}

.user_capsule-details {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
}

.user_tagging-input {
  font-weight: 400;
  font-size: 0.875rem;
  color: #000;
  padding: 0.5rem 0;
}

.user_tagging-input::placeholder {
  font-weight: 400;
  font-size: 0.875rem;
  color: #9a9a9a;
}

.user-option {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.user-option span {
  font-weight: 600;
  font-size: 0.875rem;
}

.user_tagging-suggestions {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0.625rem;
  list-style: none;
}

.tagged_users-list {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.chat-section {
  flex-grow: 1;
  overflow-y: auto;
}

.chat-section::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* Firefox scrollbar hiding */
.chat-section {
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.message-sender.me {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.message-sender.me .message-sender_details {
  text-align: right;
}
