.team_topbar {
  padding: 10px 2rem;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.team_topbar-notifications_container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.team_topbar-notification {
  position: absolute;
  height: 13px;
  width: 13px;
  background-color: #ff3a3a;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -2px;
  color: #fff;
  font-size: 8px;
}
