:root {
  --gradient: linear-gradient(102deg, #369d9c 0%, #28814d 100%);
}

.team-members_container {
  padding: 5px 32px 0 32px;
  height: 100%;
  max-width: 100%;
  height: 100%;
  /* overflow: auto; */
}

.tab-panel {
  width: 100%;
  flex-grow: 1;
}

.tab-panel_child {
  width: 100%;
  padding: 1rem 0 1rem 0;
}

.back-button {
  height: 16px;
  width: 16px;
  border: none;
  cursor: pointer;
}

.team-members_heading_container {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.team-members_heading_container h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.team-members-input_container {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 16px;
}

.team-members-input .MuiOutlinedInput-input {
  padding: 10px;
  border-radius: 8px;
  max-width: 320px;
}

.team-members-add_member {
  padding: 8px 20px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--gradient);
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

/* MODAL */
.team-members_modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  padding: 3px 0;
}

.team-members_modal-header h2 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.team-members_modal-seats {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  background-color: #f7f7f7;
  padding: 0 10px;
}

.team-members_modal-seats span {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
}

.email-container {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.success_image {
  height: 103px;
  width: 118px;
  object-fit: cover;
}

.invite_sent {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  margin: 18px 0 45px 0;
}

/* TABLE CLASSES START */

.table-container {
  /* max-width: 100%; */
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* min-width: 600px; */
  max-width: 100%;
  /* overflow: auto; */
}

.table-row {
  display: flex;
  align-items: center;
  border: 1px solid #ede5e5;
  border-radius: 8px; /* Rounded corners for each row */
  /* height: 54px; */
}

.table-header {
  font-weight: 400;
  color: #8a8686cc;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: -1rem;
}

.table-cell {
  flex: 1;
  padding: 0.875rem 1rem;
  text-align: left;
  vertical-align: middle;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 22.5px;
  white-space: nowrap;
  min-width: 150px;
}

.table-cell:last-child {
  max-width: 130px;
  text-align: center;
  font-weight: 400;
  padding: 0;
}

/* TABLE CLASSES END */

.edit-modal_title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.7rem;
  margin-bottom: 2rem;
}

.edit-modal_input_container {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.edit-modal_close-btn {
  padding: 8px 1.5rem;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--gradient);
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
