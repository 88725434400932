.reset-password_container {
  height: 100vh;
  display: flex;
  width: 100%;
}

.video-player {
  width: 50%;
}

.reset-form_container {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.reset-form_div {
  max-width: 31rem;
  width: 100%;
  padding: 50px 40px;
}

.edit-email_div_container {
  margin-bottom: 40px;
}

.edit-email_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
}

.edit-email_div_email {
  font-size: 14px;
  font-weight: 500;
  color: #585555;
}

.edit-email_div button {
  color: #369d9c;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.resend-otp-div {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}

.resend-otp-div .resend {
  cursor: pointer;
}

.password_changed {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 20px;
  letter-spacing: 0.0024em;
}
