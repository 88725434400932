@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body fieldset {
  border-color: #c5d2cb !important;
}
/* ::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #b0dfc9; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
  height: 20px; /* Height of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9fccb3; /* Darker color on hover */
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Background color of the scrollbar */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #b0dfc9 #f5f5f5; /* Thumb color and background color */
}

::-moz-scrollbar-button,
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
/* Remove the scrollbar on number type input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ge3s_green_button {
  width: 100%;
  padding: 12px 0;
  border-radius: 6px;
  font-size: 1rem;
  color: #fff;
  border: none;
  cursor: pointer;
  background: linear-gradient(102deg, #369d9c 0%, #28814d 100%);
}

.green_text_gradient {
  background: linear-gradient(101.74deg, #369d9c 0%, #28814d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

button:active {
  transform: translateY(2px);
}

.gradient-btn {
  padding: 8px 1.5rem;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--gradient);
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
}

.gradient-btn:hover {
  background: linear-gradient(270deg, #00191d 0%, #67b48d 100%);
}

.gradient-btn:disabled {
  background: #e8e8e8;
  color: #a2a2a2;
}

.outlined-gradient-btn {
  background: none;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 8px 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  color: #369d9c;
  text-transform: capitalize;
  transition: all 0.3s ease;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, #369d9c, #28814d);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.outlined-gradient-btn:hover {
  background-image: linear-gradient(#f2fff3, #f2fff3),
    linear-gradient(270deg, #00191d 0%, #67b48d 100%);
}
