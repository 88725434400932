.chat-tray {
  position: fixed;
  width: 460px;
  max-height: 420px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  animation: slideIn 0.2s ease-out forwards;
  border: 1px solid #e5e7eb;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-tray-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #dfdfdf;
  background-color: #ffffff;
}

.chat-tray-header h2 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  margin: 0;
  line-height: 30px;
}

.close-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  color: #6b7280;
  transition: background-color 0.2s ease;
}

.close-button:hover {
  background: #f3f4f6;
}

.chat-tray-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  padding-right: 32px;
  max-height: calc(420px - 65px); /* Header height + padding */
}

.message-item {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f3f4f6;
}

.message-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.message-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.message-content {
  flex: 1;
  min-width: 0;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
}

.message-header h3 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 0;
  line-height: 22px;
}

.message-time {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #bababa;
}

.message-context {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #b7b7b7;
  margin: 4px 0;
}

.message-text {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #464646;
  margin: 4px 0;
  line-height: 24px;
}

.view-report {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #28814d;
  text-decoration: none;
  margin-top: 6px;
  transition: color 0.2s ease;
  line-height: 16.94px;
}

.view-report:hover {
  color: #059669;
  text-decoration: underline;
}

/* Custom scrollbar */
.chat-tray-content::-webkit-scrollbar {
  width: 6px;
}

.chat-tray-content::-webkit-scrollbar-track {
  background: #f3f4f6;
}

.chat-tray-content::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 3px;
}

.chat-tray-content::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

/* Firefox scrollbar */
.chat-tray-content {
  scrollbar-width: thin;
  scrollbar-color: #d1d5db #f3f4f6;
}
