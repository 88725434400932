.rejected-container {
  padding: 1rem 1.5rem;
}

.rejected-tables_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rejected-table_title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.rejected-kpi-table-container {
}

.rejected-kpi-table {
  max-width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.rejected-kpi-table-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.rejected-kpi-table {
  max-width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.rejected-kpi-table-header {
  margin-bottom: 10px;
  border: none !important;
}

.rejected-kpi-table-head-row,
.rejected-kpi-table-row {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr;
  background-color: white;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  border-bottom: none;
}

.rejected-kpi-table-head-row {
  background-color: #fcfcfc;
  border: none !important;
}

.rejected-kpi-table-cell {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  position: relative;
  min-width: 0; /* Ensure cells don't overflow */
  min-width: 150px;
}

.header-cell {
  border: none !important;
  background-color: #fcfcfc !important;
}

.rejected-kpi-table-cell:last-child {
  border-right: none;
}

.rejected-kpi-table-cell:first-child {
  border-left: none;
}

/* Header cell styles */
.rejected-kpi-table-header-content {
  padding: 8px 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  border: none !important;
  white-space: nowrap;
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 600;
}

/* Center only specific header cells */
.rejected-kpi-table-head-row
  .rejected-kpi-table-cell:nth-child(1)
  .rejected-kpi-table-header-content,
.rejected-kpi-table-head-row
  .rejected-kpi-table-cell:nth-child(4)
  .rejected-kpi-table-header-content,
.rejected-kpi-table-head-row
  .rejected-kpi-table-cell:nth-child(5)
  .rejected-kpi-table-header-content {
  justify-content: center;
}

/* Content cell styles */
.rejected-kpi-table-cell-content {
  padding: 0 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 500;
}

/* Center specific columns in content */
.rejected-kpi-table-cell:nth-child(1) .rejected-kpi-table-cell-content,
.rejected-kpi-table-cell:nth-child(4) .rejected-kpi-table-cell-content,
.rejected-kpi-table-cell:nth-child(5) .rejected-kpi-table-cell-content {
  justify-content: center;
}

/* Data and input wrapper styles */
.data,
.rejected-input-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
}

.data:last-child,
.rejected-input-wrapper:last-child {
  border-bottom: none;
}

.rejected-input-field {
  width: 75%;
  padding: 8px 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  border: 0.5px solid #ed4a3c;
}

.rejected-input-field::placeholder {
  color: #ed4a3c;
  font-size: 10px;
}

.rejected-kpi-table-row:first-child .rejected-kpi-table-cell {
  border-top: none;
}

.rejected-kpi-table-row:last-child .rejected-kpi-table-cell {
  /* border-bottom: none; */
}

.rejected-kpi-table-body-row {
  margin-bottom: 30px;
}

.rejected-kpi-table-cell > .rejected-kpi-table-cell-content:last-child {
  border-bottom: none;
}

.assign-btn {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid #77bcbb;
}
