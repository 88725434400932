.signup_verify_btn {
  margin-top: 2rem;
  width: 100%;
  padding: 12px 0px;
  border-radius: 6px;
  font-size: 1rem;
  color: #fff;
  border: none;
  cursor: pointer;
  background: linear-gradient(102deg, #369d9c 0%, #28814d 100%);
}
.signup_verify_btn:disabled {
  background: #a0a0a0;
  cursor: default;
}

.register_account_btn {
  margin-top: 0.8rem;
  width: 100%;
  padding: 12px 0px;
  border-radius: 6px;
  font-size: 1rem;
  color: #fff;
  border: none;
  cursor: pointer;
  background: linear-gradient(102deg, #369d9c 0%, #28814d 100%);
}

.register_account_btn:disabled {
  background: #a0a0a0;
  cursor: default;
}

.login_text {
  font-size: 0.9rem;
  font-weight: 400;
  color: #717171;
  text-align: center;
  margin-top: 20px;
}
.login_text2 {
  font-size: 0.9rem;
  font-weight: 400;
  color: #717171;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.login_text span {
  font-size: 0.9rem;
  font-weight: 400;
  background: linear-gradient(102deg, #369d9c 0%, #28814d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
